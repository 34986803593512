<template>
  <div>
    <iframe class="pg" :src="url"></iframe>
  </div>
</template>

<script>
  export default {
    name: "old-billing-pc",
    computed: {
      url() {
        //let pg = 'https://master.launchpack.co.kr:8081';
        let pg = 'http://13.209.97.215:8081';
        //let pg = 'http://localhost:8081';
        let basket = this.$store.getters.basket;
        let user = this.$store.getters.user;

        let url = `${pg}/inicis/pay/lpPayBill.php`;
        url += `?goodname=${basket.title}`;
        url += `&price=${basket.price[basket.priceType].billing}`;
        url += `&buyername=${user.name}`;
        url += `&buyertel=${user.phone}`;
        url += `&buyeremail=${user.email}`;

        return url;
      }
    }
  }
</script>

<style scoped>
  .pg {
    position: fixed;
    z-index: 10;
    top: 42%;
    left: 50%;
    width: 822px;
    transform: translate(-50%, -50%);
    height: 602px;
    border: 0;
  }
</style>
